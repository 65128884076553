header {
    width: 100%;
    height: 100px;
    background-color: black;
    box-shadow: 2px 2px 4px 2px rgba(128, 120, 120, 0.2);
    position: fixed;
    top: 0; /* Position the navbar at the top of the page */
}
header .navbar{
    max-width: 1240px;
    height: 100%;
    padding: 0 1rem;
    margin: auto;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    
    
}
header .logo {
    display: flex;
    align-items: center;
}
body {
    color: #333;

}
p{
    font-size: 40px;
    color: gray;
}
.innerJ{
    color: white;
}
ul{
    list-style: none;
}
a {
    text-decoration: none;
    columns: #fff;
    color: white;
}
.hamburger {
    position: absolute;
    top: 2rem;
    right: 1rem;
    display: none;
}
.nav-menu{
    display: flex;
    align-items: center;
    justify-self: start;
    

}
.nav-item{
    padding: 0 1.5rem;
}
.nav-link:hover{
    color:#3ac790;
}

@media screen and (max-width: 960px){
    .hamburger{
        display: block;
    }
    .nav-menu{
        position: fixed;
        right: -100%;
        flex-direction: column;
        justify-content: center;
        background-color: black;
        width: 50%;
        height: 100%;
        text-align: center;
        transition: .5s;
        box-shadow: 0 2px 4px 0 rgba(128, 120, 120, 0.2);


    }
    .nav-menu.active{
        right: 0;
    }
    .nav-item {
        margin:1.5rem 0;
        font-size: 1.8rem;
        border-radius: 10%;
    
    }
    
}